<template>
  <div class="ml-10 mr-10">
    <div class="w-full justify-center">
      <p style="color: red">
        {{ "please click (Add To List) after choose the items" }}
      </p>

      <div class="vx-row justify-center">
        <div
          v-for="item in SurgeryDoctorItems"
          :key="item.ID"
          class="vx-row justify-left xl:w-1/2 sm:w-1/4 mt-5"
        >
          <vs-checkbox class="vx-col lg:w-1/2" v-model="item.IsChecked">{{
            item.Doctor.Name
          }}</vs-checkbox>

          <span class="h-12 vx-col lg:w-1/4" alt="" :src="item.Doctor.Name" />
        </div>
      </div>
    </div>
    <div class="w-full mt-10">
      <div class="vx-col lg:w-3/4"></div>
      <vs-button
        color="rgb(30, 20, 79) "
        class="vx-col"
        type="filled"
        @click="UpdateDoctor"
        >{{ $t("Add To List") }}</vs-button
      >
    </div>
  </div>
</template>
 <script>
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";
// import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import moduleHospital from "@/store/Hospital/moduleHospital.js";

// import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js";

export default {
  components: {},
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",

      HospitalSurgeryIncludedItems: [],
      options: [],
      DoctorList: [],
      radios1: "luis",
    };
  },
  props: {
    HospitalSurgeryModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    UpdateDoctor() {
      debugger;
      this.HospitalSurgeryModel.HospitalSurgeryDoctors =
        this.SurgeryDoctorItems.filter((obj) => obj.IsChecked).map((b) => {
          //  function(val) {
          debugger;
          var obj = {};
          obj.DoctorID = b.DoctorID;
          //obj.HospitalSurgeryID = this.HospitalSurgeryModel.ID;

          return obj;
        });
      debugger;
      this.$vs.dialog({
        type: "alert",
        color: "success",
        title: this.$t("done"),
        text: this.$t("the doctor add to list"),
        accept: this.acceptAlert,
        acceptText: this.$t("Ok"),
      });
    },
  },
  computed: {
    SurgeryItems() {
      return this.$store.state.SurgeryItemList.surgeryItems;
    },
    SurgeryDoctorItems() {
      return this.$store.state.SurgeryItemList.surgeryDoctorItems;
    },
  },
  created() {
    if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }

    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    debugger;
    var model = {};
    //this.HospitalSurgeryModel.HospitalID
    model.HospitalID = this.$route.params.ID;
    if (
      this.HospitalSurgeryModel.SpecialtyID != null &&
      this.HospitalSurgeryModel.SpecialtyID != 0
    ) {
      debugger;
      model.SpecialtyID = this.HospitalSurgeryModel.SpecialtyID;
    } else {
      debugger;
      model.SpecialtyID = this.HospitalSurgeryModel.Surgery.SpecialtyID;
    }
    debugger;

    if (
      this.HospitalSurgeryModel.HospitalSurgeryDoctors == null ||
      this.SurgeryDoctorItems == null ||
      this.HospitalSurgeryModel.HospitalSurgeryDoctors.length == 0 ||
      this.SurgeryDoctorItems.length == 0
    ) {
      this.$store
        .dispatch("SurgeryItemList/GetHospitalDoctorItems", model)
        .then((res) => {
          debugger;
          //commit("", res.data.Data);

          //this.SurgeryDoctorItems = res.data.Data;
          debugger;
        });
    }
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: #5dbeff;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
</style>
