<template>
  <div class="ml-10 mr-10">
    <div class="w-full justify-center">
      <p style="color: red">
        {{ "please check to  choose the items included or excluded" }}
      </p>

      <div class="vx-row w-full">
        <div class="vx-col lg:w-1/2 w-full">
          <h4>Surgery Included items</h4>
          <div
            v-for="item in SurgeryIncludedItems"
            :key="item.ID"
            class="lg:w-3/4 w-full sm:w-1/4 mt-4"
          >
            <vs-checkbox @input="UpdateHospital(item,true)" v-model="item.IsChecked">{{
              item.Name
            }}</vs-checkbox>

            <span class="" alt="" :src="item.Name" />
          </div>
        </div>
        <div class="vx-col lg:w-1/2 w-full">
          <h4>Surgery Excluded items</h4>
          <div
            v-for="item in SurgeryExcludedItems"
            :key="item.ID"
            class="lg:w-3/4 w-full sm:w-1/4 mt-4"
          >
            <vs-checkbox @input="UpdateHospital(item,false)" v-model="item.IsChecked">{{
              item.Name
            }}</vs-checkbox>

            <span class="w-full" alt="" :src="item.Name" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";

// import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js";

export default {
  components: {},
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",

      HospitalSurgeryIncludedItems: [],
      HospitalSurgeryExcludedItems: [],
      options: [],
      radios1: "luis",
    };
  },
  props: {
    HospitalSurgeryModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    UpdateHospital(item,IsInclude) {
      this.HospitalSurgeryModel.HospitalSurgeryIncludedItems = this.SurgeryIncludedItems.filter(
        (obj) => obj.IsChecked
      ).map((b) => {
        //  function(val) {
        debugger;
        var obj = {};
        obj.SurgeryItemID = b.ID;
        obj.HospitalSurgeryID = this.HospitalSurgeryModel.ID;

        return obj;
      });
      debugger;
      this.HospitalSurgeryModel.HospitalSurgeryExcludedItems = this.SurgeryExcludedItems.filter(
        (obj) => obj.IsChecked
      ).map((b) => {
        //  function(val) {
        debugger;
        var obj = {};
        obj.SurgeryItemID = b.ID;
        obj.HospitalSurgeryID = this.HospitalSurgeryModel.ID;

        return obj;
      });
      // if(IsInclude==true)
      // this.SurgeryExcludedItems.find(x=>x.ID == item.ID).IsChecked= !this.SurgeryIncludedItems.find(x=>x.ID == item.ID).IsChecked;
      // else
      // this.SurgeryIncludedItems.find(x=>x.ID == item.ID).IsChecked= !this.SurgeryExcludedItems.find(x=>x.ID == item.ID).IsChecked;
    },
  },
  computed: {
    SurgeryItems() {
      debugger;
      return this.$store.state.SurgeryItemList.surgeryItems;
    },
    SurgeryIncludedItems() {
      debugger;
      return this.$store.state.SurgeryItemList.surgeryIncludedItems;
    },
    SurgeryExcludedItems() {
      return this.$store.state.SurgeryItemList.surgeryExcludedItems;
    },
  },
  created() {
    debugger;
    if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }
    debugger;
     if(this.HospitalSurgeryModel.SurgeryID && this.HospitalSurgeryModel.SurgeryID!=null)
      {
        debugger
             if (
               this.SurgeryIncludedItems == null ||
              this.SurgeryIncludedItems.length == 0 ||
              this.SurgeryIncludedItems == undefined
                ) {

               debugger
                    this.$emit("changeInclude");
                      }

              else if (
                   this.HospitalSurgeryModel.HospitalSurgeryIncludedItems == null ||
                    this.HospitalSurgeryModel.HospitalSurgeryIncludedItems.length == 0 ||
                    this.HospitalSurgeryModel.HospitalSurgeryIncludedItems == undefined
                  ) {
                      debugger
                    this.$emit("changeInclude");


                    }
      }
    else
    {


        this.$store.state.SurgeryItemList.surgeryItems=[];
        this.HospitalSurgeryModel.HospitalSurgeryIncludedItems=[];
         this.SurgeryIncludedItems=[];
        this.SurgeryExcludedItems=[];
        this.$store.state.SurgeryItemList.surgeryIncludedItems=[];
       this.$store.state.SurgeryItemList.surgeryExcludedItems=[];


    }
    debugger;
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: #5dbeff;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
</style>
