<template>
  <div id="data-list-list-view">
    <p style="color: red">{{ "please click (Add To List) after choose the items" }}</p>
    <!--Description-->
    <vs-input
      class="w-full"
      data-vv-validate-on="blur"
      name="Description"
      icon-pack="feather"
      v-model="itemModel.Description"
      :icon-after="true"
      :label="$t('Description')"
    />
    <div class="vx-row">
      <v-select
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        class="vx-col mt-2 lg:w-1/2"
        label="Name"
        v-model="itemModel.DocumentID"
        :options="DocumentTypes"
        :reduce="(ID) => ID.ID"
        :placeholder="$t('DocumentType')"
      />
      <v-select
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        v-if="itemModel.DocumentID == 1"
        class="vx-col mt-2 lg:w-1/2"
        label="Name"
        v-model="itemModel.ScanType"
        :options="ScanTypes"
        :reduce="(ID) => ID"
        placeholder="ScanType"
      />

      <v-select
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        v-if="itemModel.DocumentID == 2"
        class="vx-col mt-2 lg:w-1/2"
        label="Name"
        v-model="itemModel.AnalysisType"
        :options="AnalysisTypes"
        :reduce="(ID) => ID"
        placeholder="AnalysisType"
      />
      <v-select
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        v-if="itemModel.DocumentID == 3"
        class="vx-col mt-2 lg:w-1/2"
        label="Name"
        v-model="itemModel.ReportType"
        :options="ReportTypes"
        :reduce="(ID) => ID"
        placeholder="ReportType"
      />
    </div>

    <div class="w-full mt-3">
      <div class="vx-col lg:w-3/4"></div>
      <vs-button
        color="rgb(30, 20, 79) "
        class="vx-col"
        type="filled"
        @click="UpdateDocList"
        >{{ $t("Add To List") }}</vs-button
      >
    </div>
    <div class="mt-3">
      <!-- table of doc -->
      <vx-card class="flex-1" v-bind:title="$t('Required Document')">
        <vs-table
          ref="table"
          pagination
          :max-items="5"
          :data="DocumentList"
        >
          <template slot="thead">
            <vs-th> {{ $t("Description") }}</vs-th>
            <vs-th> {{ $t("Document Type") }}</vs-th>
            <vs-th> {{ $t("Document Type Name") }}</vs-th>
          </template>

          <template slot-scope="{ data }">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.Description">
                    {{ tr.Description }}
                  </p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate" v-if="tr.ScanTypeID > 0">
                    Scan
                  </p>
                  <p
                    class="product-name font-medium truncate"
                    v-else-if="tr.ReportTypeID > 0"
                  >
                    Report
                  </p>
                  <p class="product-name font-medium truncate" v-else>Analysis</p>
                </vs-td>
                <vs-td>
                  <p class="product-name font-medium truncate">
                    {{ tr.DocumentTypeName }}
                  </p>
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
    <!-- <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
           <vs-button type="border" color="danger" :to="{ name: 'Hospital' }">{{
            $t("Cancel")
          }}</vs-button>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";

import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Datepicker from "vuejs-datepicker";
// import moment from "moment";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
//import hospitalTab from "@/views/tabs/hospitalTab.vue";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";

import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";

export default {
  components: {
    VuePerfectScrollbar,
    Datepicker,
    // hospitalTab,

    // vSelect,
  },

  computed: {
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
    
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.3,
        search: {},
        years: [],
      },
      itemModel: {
        Description: "",
        DocumentID: null,
        ScanTypeID: null,
        AnalysisTypeID: null,
        ReportTypeID: null,
        ScanType: {},
        AnalysisType: {},
        ReportType: {},
      },
      DocumentList: [],
      isMounted: false,
      baseURL: domain,
      rowDataForDelete: {},

      activePrompt: false,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Course/UploadFile",
      HospitalSurgeries: [],
    };
  },
  props: {
    HospitalSurgeryModel: {
      type: Object,
      required: true,
    },
  },

  methods: {
    DocumentObject(id) {
      var x = this.DocumentTypes.filter((obj) => obj.ID == id);
      debugger;
      return x[0].Name;
    },
    DocumentDetailsObject(ReportObj) {
      var x;
      if (ReportObj.ScanTypeID != null) {
        x = this.ScanTypes.filter((obj) => obj.ID == ReportObj.ScanTypeID);
      } else if (ReportObj.AnalysisTypeID != null) {
        x = this.AnalysisTypes.filter((obj) => obj.ID == ReportObj.AnalysisTypeID);
      } else if (ReportObj.ReportTypeID != null) {
        x = this.ReportTypes.filter((obj) => obj.ID == ReportObj.ReportTypeID);
      } else {
        return null;
      }

      debugger;
      return x[0].Name;
    },
    UpdateDocList() {
      debugger;
      if (this.itemModel.DocumentID == 1) {
        this.itemModel.ScanTypeID = this.itemModel.ScanType.ID;
        this.itemModel.DocumentTypeName = this.itemModel.ScanType.NameEN;
      } else if (this.itemModel.DocumentID == 2) {
        this.itemModel.AnalysisTypeID = this.itemModel.AnalysisType.ID;
        this.itemModel.DocumentTypeName = this.itemModel.AnalysisType.NameEN;
      } else {
        this.itemModel.ReportTypeID = this.itemModel.ReportType.ID;
        this.itemModel.DocumentTypeName = this.itemModel.ReportType.NameEN;
      }
      debugger;
      if (this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments == undefined) {
        debugger;
        this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments = [];
      }
      debugger;
      this.DocumentList.push(this.itemModel);
      // this.DocumentList.push(this.itemModel);
      //  this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments.push(this.itemModel);
     this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments = this.DocumentList;
      debugger;
      // this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments = this.DocumentList;
      this.itemModel = {
        Description: "",
        DocumentID: null,
        ScanTypeID: null,
        AnalysisTypeID: null,
        ReportTypeID: null,
      };
      debugger;
    },
  },

  created() {
    debugger;
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    debugger;
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    // this.GetSurgery();
    //this.resetData();
    const ID = this.$route.params.ID;
    // if (ID != undefined) {
    //   this.getHospitalSurgeries(ID);
    // }
    if(this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments!=undefined&&this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments.length>0)
       this.DocumentList= this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments;
else
 this.DocumentList =[];
  },
};
</script>
<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
