
export default {
  ADD_ITEM(state, item) {
    state.Hospitals.unshift(item)
  },
 
  SET_languages(state, languages) {
    state.languages = languages
  },
  SET_specialities(state, specialities) {
    state.specialities = specialities
  },
  UPDATE_HospitalSurgery(state, Hospital) {
    const HospitalIndex = state.Hospitals.findIndex((p) => p.ID == Hospital.ID)
    Object.assign(state.Hospitals[HospitalIndex], Hospital)
},
REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.Hospitals.findIndex((p) => p.ID == itemId)
    state.Hospitals.splice(ItemIndex, 1)
},
SET_Hospitalurgeries(state, HospitalSurgeries) {
  state.HospitalSurgeries = HospitalSurgeries
},
SET_PatientReservationSurgeries(state, PatientReservationSurgeries) {
  state.PatientReservationSurgeries = PatientReservationSurgeries
},
SET_HospitalDoctors(state, HospitalDoctors) {
  state.HospitalDoctors = HospitalDoctors
},
}
