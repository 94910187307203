<template>
  <div>
    <div class="vx-row">
      <!-- Specialty -->
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Specialty") }}*</label>
        <v-select
          label="Name"
          class="w-full"
          v-if="HospitalSurgeryModel.Surgery == null"
          v-model="HospitalSurgeryModel.SpecialtyID"
          :options="specialities"
          :reduce="(ID) => ID.ID"
          @input="FilterSurgery(HospitalSurgeryModel.SpecialtyID)"
        />
        <v-select
          label="Name"
          class="w-full"
          v-else
          v-model="HospitalSurgeryModel.Surgery.SpecialtyID"
          :options="specialities"
          :reduce="(ID) => ID.ID"
          @input="FilterSurgery(HospitalSurgeryModel.Surgery.SpecialtyID)"
        />
      </div>
      <!-- Surgery -->
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Surgery") }}*</label>
        <v-select
          label="Name"
          class="mw-full"
          @input="$emit('changeInclude')"
          v-model="HospitalSurgeryModel.SurgeryID"
          :options="FilterListSurgery"
          :reduce="(ID) => ID.ID"
        />
      </div>

     <div class="vx-col lg:w-1/4 w-full" v-if="false">
        <div >{{ $t("SurgeryCategory") }}*</div>
        <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full"
          label="Name"
           v-if="HospitalSurgeryModel.Surgery == null"
          v-model="HospitalSurgeryModel.SurgeryCategoryID"
          :options="surgeryCategories"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('SurgeryCategory')"
          
        >
        </v-select>
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          class="w-full"
          label="Name"
           v-else
          v-model="HospitalSurgeryModel.Surgery.SurgeryCategoryID"
          :options="surgeryCategories"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('SurgeryCategory')"
         
        >
        </v-select>
     </div>
     

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Currency") }}*</label>
        <v-select
          label="Name"
          name="CurrencyID"
          class="mw-full"
          v-model="HospitalSurgeryModel.CurrencyID"
          :options="currencies"
          :reduce="(ID) => ID.ID"
        />
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Default Doctor Price") }}</label>
        <vs-input
          v-model="HospitalSurgeryModel.DefaultDoctorPrice"
          class="w-full"
          name="DefaultDoctorPrice"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('DefaultDoctorPrice')">{{
          errors.first("DefaultDoctorPrice")
        }}</span>
      </div>

      <div class="vx-col lg:w-1/4 w-full">
        <label class="vs-input--label">{{ $t("Default Hospital Price") }}*</label>
        <vs-input
          v-model="HospitalSurgeryModel.DefaultHospitalPrice"
          class="w-full"
          name="DefaultHospitalPrice"
        />
      </div>
      <div class="vx-col lg:w-1/4 w-full mt-12">
        <vs-checkbox v-model="HospitalSurgeryModel.IsFixedPrice"
          >{{ $t("Is Fixed Price") }}
        </vs-checkbox>
      </div>
    </div>
    <div class="vx-row mt-8 ml-1">
      <div class="vx-col lg:w-1/2 w-full">
        <label class="vs-input--label">{{ $t("Discount") }}</label>
        <vs-radio
          v-model="HospitalSurgeryModel.IsPercentage"
          :vs-value="false"
          class="mr-4"
          vs-name="IsPercentage"
          >{{ $t("Value") }}</vs-radio
        >
        <vs-radio
          v-model="HospitalSurgeryModel.IsPercentage"
          :vs-value="true"
          class="mr-4"
          vs-name="IsPercentage"
          >{{ $t("Percentage") }}</vs-radio
        >
        <vs-input
          v-if="
            HospitalSurgeryModel.IsPercentage != false &&
            HospitalSurgeryModel.IsPercentage != null
          "
          class="w-full"
          label="Percentage Discount"
          v-model="HospitalSurgeryModel.PercentageDiscount"
        />
        <vs-input
          v-if="
            HospitalSurgeryModel.IsPercentage != true &&
            HospitalSurgeryModel.IsPercentage != null
          "
          class="w-full"
          label="Discount"
          v-model="HospitalSurgeryModel.Discount"
        />
      </div>
    
    </div>

      <div >
          <vs-checkbox
          class="mt-1 w-full justify-left"
          v-model="HospitalSurgeryModel.Active"
          >{{ $t("Active") }}
        </vs-checkbox>
      </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import { domain, mainSetting } from "@/gloabelConstant.js";
import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import Datepicker from "vuejs-datepicker";
import moduleHospital from "@/store/Hospital/moduleHospital.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";

export default {
  components: {
    vSelect,
    Datepicker,
  },
  props: {
    HospitalSurgeryModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
      surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
    Surgeries() {
      debugger;
      return this.$store.state.SurgeryList.surgeries;
    },
    specialities() {
      return this.$store.state.HospitalList.specialities;
    },
  },
  data() {
    return {
      FilterListSurgery: [],
      
      isMounted: false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "API/Common/UploadFile",
    };
  },
  methods: {
    FilterSurgery(specialtyID) {
      debugger;
      this.FilterListSurgery = this.Surgeries.filter((x) => x.SpecialtyID == specialtyID);
      debugger;
      if (this.FilterListSurgery[0] != undefined) {
        this.HospitalSurgeryModel.SurgeryID = this.FilterListSurgery[0].ID;
        debugger;
      } else {
        this.HospitalSurgeryModel.SurgeryID = null;
        debugger;
      }
    },
  },
  created() {
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }

    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
   var vm=this;
    this.$store.dispatch("SurgeryList/GetAllSurgeries").then((res) => {
      debugger;
      
      if(vm.HospitalSurgeryModel.Surgery && vm.HospitalSurgeryModel.Surgery.SpecialtyID)
      {
        var specialtyID=vm.HospitalSurgeryModel.Surgery.SpecialtyID;
            vm.FilterListSurgery = vm.Surgeries.filter((x) => x.SpecialtyID == specialtyID);
      }
      debugger;
    });

    // this.$store.dispatch("SurgeryList/GetAllSurgeries");
    // this.FilterListSurgery = this.Surgeries;
    debugger;
    var object = {};
    debugger;
    object.HospitalID = this.HospitalSurgeryModel.HospitalID;
    debugger;
    this.$store.dispatch("HospitalList/GetAllHospitalSpecialties", object);
 if(!this.currencies || this.currencies.length==0)
    this.$store.dispatch("CurrencyList/GetAllCurrencies");

    if(!this.surgeryCategories || this.surgeryCategories.length==0)
      this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");
    
  },
};
</script>
<style></style>
