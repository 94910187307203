/*=========================================================================================
  File Name: moduleDoctorActions.js
  Description: Doctor Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";


export default {
  AddHospitalSurgery(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/API/HospitalSurgery/AddHospitalSurgery", item)
        .then(response => {
          // commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetHospitalSurgery(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("API/HospitalSurgery/GetHospitalSurgery?ID=" + itemid)
        .then(response => {
          // commit('UPDATE_Doctor', response.data.Data)
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllHospitalsSurgeries({ commit }, search) {
    return new Promise((resolve, reject) => {
      axios
        .post("api/HospitalSurgery/SearchHospitalSurgerys", search)
        .then(response => {
          commit("SET_HospitalSurgery", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  UpdateHospitalSurgery(context, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/API/HospitalSurgery/UpdateHospitalSurgery", item)
        .then(response => {
       
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetHospitalSurgeries({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/API/HospitalSurgery/SearchHospitalSurgerys",
          item
        )
        .then(response => {
          commit("SET_Hospitalurgeries", response.data.Data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },



  DeleteHospitalSurgery({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .delete("api/HospitalSurgery/DeleteHospitalSurgery?ID=" + item.ID)
        .then(response => {
          commit("REMOVE_ITEM", item.ID);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};
