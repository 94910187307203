<template>
  <div class="ml-10 mr-10">
    <div class="w-full justify-center">
      <p style="color:red">{{ "please click (Add To List) after choose the items" }}</p>

      <div class="vx-row justify-center">
        <div
          v-for="item in SurgeryExcludedItems"
          :key="item.ID"
          class="vx-row justify-left xl:w-1/2 sm:w-1/4 mt-5"
        >
          <vs-checkbox class="vx-col lg:w-1/2" v-model="item.IsChecked">{{
            item.Name
          }}</vs-checkbox>

          <span class="h-12 vx-col lg:w-1/4" alt="" :src="item.Name" />
        </div>
      </div>
    </div>
    <div class="w-full mt-10">
      <div class="vx-col lg:w-3/4"></div>
      <vs-button
        color="rgb(30, 20, 79) "
        class="vx-col"
        type="filled"
        @click="UpdateHospital"
        >{{ $t("Add To List") }}</vs-button
      >
    </div>
  </div>
</template>
<script>
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";

// import axios from "@/axios.js"
import { domain, mainSetting } from "@/gloabelConstant.js";

export default {
  components: {},
  data() {
    return {
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",

      HospitalSurgeryExcludedItems: [],
      options: [],
      radios1: "luis",
    };
  },
  props: {
    HospitalSurgeryModel: {
      type: Object,
      required: true,
    },
  },
  methods: {
    UpdateHospital() {
      debugger;
      this.HospitalSurgeryModel.HospitalSurgeryExcludedItems =
        this.SurgeryExcludedItems.filter((obj) => obj.IsChecked).map((b) => {
          //  function(val) {
          debugger;
          var obj = {};
          obj.SurgeryItemID = b.ID;
          obj.HospitalSurgeryID = this.HospitalSurgeryModel.ID;

          return obj;
        });
      this.$vs.dialog({
        type: "alert",
        color: "success",
        title: this.$t("Done"),
        text: this.$t("the excluded items add to list"),
        accept: this.acceptAlert,
        acceptText: this.$t("Ok"),
      });
    },
  },
  computed: {
    SurgeryItems() {
      return this.$store.state.SurgeryItemList.surgeryItems;
    },
    SurgeryExcludedItems() {
      return this.$store.state.SurgeryItemList.surgeryExcludedItems;
    },
  },
  created() {
    debugger;
    if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }
    debugger;
    if (
      this.HospitalSurgeryModel.HospitalSurgeryExcludedItems == null ||
      this.HospitalSurgeryModel.HospitalSurgeryExcludedItems.length == 0 ||
      this.SurgeryExcludedItems.length == 0 ||
      this.SurgeryExcludedItems == null
    ) {
      this.$store
        .dispatch(
          "SurgeryItemList/GetHospitalSurgeryExcludedItems",
          this.HospitalSurgeryModel.ID
        )
        .then((res) => {});
    }
    this.SurgeryExcludedItems;
    debugger;
  },
};
</script>
<style>
.circle {
  width: 10px;
  height: 10px;
  background: #5dbeff;
  border-radius: 50%;
}
.textfont {
  font-family: "futuraMedium";
}
.material-icons {
  font-family: "Material Icons" !important;
}
.material-icons :lang(en) {
  font-family: unset;
}
</style>
