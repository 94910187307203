<template>
  <div>
    <feather-icon
      icon="ArrowLeftIcon"
      class="mb-4"
      svgClasses="h-8"
      style="cursor: pointer"
      @click="$router.go(-1)"
    />
    <vs-alert color="danger" title="Course Not Found" :active.sync="Hospital_not_found">
      <span>Hospital record with id: {{ $route.params.Id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'HospitalSurgeries' }" class="text-inherit underline"
          >All Hospital</router-link
        >
      </span>
    </vs-alert>
    <div>
      <vx-card>
        <div  slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
            <vs-tab :label="$t('MainInfo')" icon-pack="feather" icon="icon-user">
              <div class="tab-text">
                <hospital-Surgery-tab-main
                  class="mt-4"
                  :HospitalSurgeryModel="HospitalSurgeryModel"
                  @changeInclude="changeInclude"
                />
              </div>
            </vs-tab>
            <vs-tab
              :label="$t('Hospital Surgery  Items')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <hospital-surgery-included-item-tab-main
                  class="mt-4"
                  @changeInclude="changeInclude()"
                  :HospitalSurgeryModel="HospitalSurgeryModel"
                />
              </div>
            </vs-tab>

            <vs-tab
              :label="$t('Hospital Surgery Doctor')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <hospital-surgery-doctor-tab-main
                
                  class="mt-4"
                  :HospitalSurgeryModel="HospitalSurgeryModel"
                />
              </div>
            </vs-tab>

            <!-- HospitalRequiredDocumentTabMain -->

            <vs-tab
              :label="$t('Surgery Required Documents')"
              icon-pack="feather"
              icon="icon-user"
            >
              <div class="tab-text">
                <hospital-required-document-tab-main
                  class="mt-4"
                  :HospitalSurgeryModel="HospitalSurgeryModel"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </div>
      </vx-card>
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8 flex flex-wrap items-center justify-end">
            <vs-button :disabled="!isFormValid" class="mr-6" @click="submitData">{{ $t("Save") }}</vs-button>
            <vs-button type="border" color="danger" :to="{ name: 'Hospital' }">{{
              $t("Cancel")
            }}</vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import HospitalTabMain  from "../Hospital/HospitalTabMain.vue";
import HospitalSurgeryTabMain from "./HospitalSurgeryTabMain.vue";
import HospitalSurgeryIncludedItemTabMain from "./HospitalSurgeryTabIncludedtems.vue";
import HospitalSurgeryExcludedItemTabMain from "./HospitalSurgeryTabExcludedtems.vue";
import HospitalSurgeryDoctorTabMain from "./HospitalSurgeryDoctor.vue";
import HospitalRequiredDocumentTabMain from "./HospitalRequiredDocument.vue";
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";

import moduleSurgeryHospital from "@/store/HospitalSurgeries/moduleHospitalSurgery.js";

export default {
  components: {
    HospitalSurgeryTabMain,
    HospitalSurgeryIncludedItemTabMain,
    HospitalSurgeryExcludedItemTabMain,
    HospitalSurgeryDoctorTabMain,
    HospitalRequiredDocumentTabMain,
  },
  data() {
    return {
      Hospital_not_found: false,
      activeTab: 0,
      HospitalSurgeryModel: {
        SurgeryID: "",
        SpecialtyID: "",
        mainFocus: [],
      },
    };
  },
  computed: {
    isFormValid()
    {
      debugger
       return  (this.HospitalSurgeryModel.SpecialtyID || ( this.HospitalSurgeryModel.Surgery &&  this.HospitalSurgeryModel.Surgery.SpecialtyID))
       && this.HospitalSurgeryModel.SurgeryID && this.HospitalSurgeryModel.CurrencyID
       && this.HospitalSurgeryModel.DefaultHospitalPrice
    }
  },
  methods: {
    initValues() {
      this.HospitalSurgeryModel = {
        HospitalSurgeryExcludedItems :[],
        HospitalSurgeryRequiredDocuments:[],
        HospitalSurgeryDoctors:[],
        HospitalSurgeryIncludedItems : [],
        Surgery:{}
      };
    },
    changeInclude() {
      debugger;
      if (
        this.HospitalSurgeryModel.SurgeryID &&
        this.HospitalSurgeryModel.SurgeryID != null
      ) {
        debugger;
        if (
          this.HospitalSurgeryModel.HospitalSurgeryIncludedItems == null ||
          this.SurgeryIncludedItems == null ||
          this.HospitalSurgeryModel.HospitalSurgeryIncludedItems.length == 0 ||
          this.SurgeryIncludedItems.length == 0
        ) {
          var obj = {};
          obj.ID = this.HospitalSurgeryModel.ID;
          debugger;
          obj.SurgeryID = this.HospitalSurgeryModel.SurgeryID;
          debugger;
          this.$store
            .dispatch("SurgeryItemList/GetNewHospitalSurgeryIncludedItems", obj)
            .then((res) => {
              debugger;
              res.data.Data;
              debugger;
              //  this.HospitalModel.HospitalFacilities.facilities = res.data.Data;
            });
          this.$store
            .dispatch("SurgeryItemList/GetNewHospitalSurgeryExcludedItems", obj)
            .then((res) => {
              debugger;
              res.data.Data;
              debugger;
              //  this.HospitalModel.HospitalFacilities.facilities = res.data.Data;
            });
        }

        debugger;
        this.SurgeryIncludedItems;
      } else {
        this.$store.state.SurgeryItemList.surgeryItems = [];
        this.HospitalSurgeryModel.HospitalSurgeryIncludedItems = [];
        this.$store.state.SurgeryItemList.surgeryIncludedItems = [];
        this.$store.state.SurgeryItemList.surgeryExcludedItems = [];
      }
    },
    submitData() {
      debugger;
      if (this.HospitalSurgeryModel.IsPercentage) {
        this.HospitalSurgeryModel.Discount = undefined;
      } else {
        this.HospitalSurgeryModel.PercentageDiscount = undefined;
      }
      debugger;
      debugger;
      if (this.HospitalSurgeryModel.ID !== null && this.HospitalSurgeryModel.ID >= 0) {
        debugger;
        this.$vs.loading();
        this.$store
          .dispatch(
            "HospitalSurgeryList/UpdateHospitalSurgery",
            this.HospitalSurgeryModel
          )
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            this.$router.push({
              name: "HospitalSurgeries",
              params: { ID: this.HospitalSurgeryModel.HospitalID },
            });
            window.showSuccess(res.data.message);
          
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showSuccess(res.data.message);
          });
      } else {
        // add new hospital surgery
        debugger;

        // if (
        //   this.HospitalSurgeryModel.SurgeryID == null ||
        //   this.HospitalSurgeryModel.SurgeryID == 0 ||
        //   this.HospitalSurgeryModel.SurgeryID == '' ||
        //   this.HospitalSurgeryModel.SpecialtyID == null ||
        //   this.HospitalSurgeryModel.SpecialtyID == 0 ||
        //   this.HospitalSurgeryModel.SpecialtyID == '' ||
        //   this.HospitalSurgeryModel.HospitalSurgeryExcludedItems == null ||
        //   this.HospitalSurgeryModel.HospitalSurgeryIncludedItems == null ||
        //   this.HospitalSurgeryModel.HospitalSurgeryDoctors == null ||
        //   this.HospitalSurgeryModel.HospitalSurgeryRequiredDocuments == null
        // ) {
        //   debugger;
        //   this.$vs.notify({
        //     color: "danger",
        //     title: "can't save",
        //     text: this.$t("please fill all data"),
        //   });

        //   return;
        // }

        this.$vs.loading();
        debugger;
        this.HospitalSurgeryModel;
        debugger;
        this.$store
          .dispatch("HospitalSurgeryList/AddHospitalSurgery", this.HospitalSurgeryModel)
          .then((res) => {
            console.error(res);
            this.$vs.loading.close();
            window.showSuccess(res.data.message);
            debugger;
            this.$router.push({
              name: "HospitalSurgeries",
              params: { ID: this.HospitalSurgeryModel.HospitalID },
            });
            //  this.$store.state.courseList.search.pageNum=1;
            this.initHospitalSurgeryModelValues();
          })
          .catch((err) => {
            this.$vs.loading.close();
            console.error(err);
            window.showSuccess(res.data.message);
          });
      }
    },
    initHospitalSurgeryModelValues() {},
  },
  created() {
    // this.initHospitalSurgeryModelValues();
    this.initValues();
    debugger;
    if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }
    if (!moduleSurgeryHospital.isRegistered) {
      this.$store.registerModule("HospitalSurgeryList", moduleSurgeryHospital);
      moduleSurgeryHospital.isRegistered = true;
    }
    // var vm = this;
    const ID = this.$route.params.ID;
    const HospitalSurgeryID = this.$route.params.HospitalSurgeryID;
    this.HospitalSurgeryModel.HospitalID = this.$route.params.ID;
    debugger;

    if (HospitalSurgeryID != undefined && HospitalSurgeryID != 0) {
      debugger;
      this.$store
        .dispatch("HospitalSurgeryList/GetHospitalSurgery", HospitalSurgeryID)
        .then((res) => {
          debugger;
          this.HospitalSurgeryModel = res.data.Data;
          debugger;
          if (this.HospitalSurgeryModel.PercentageDiscount != null) {
            this.$set(this.HospitalSurgeryModel, "IsPercentage", true);
            debugger;
          } else {
            debugger;
            this.$set(this.HospitalSurgeryModel, "IsPercentage", false);
          }
        });
    }
  },
};
</script>
<style >
.vs__dropdown-menu{ 
  max-height: 230px!important;
}
</style>